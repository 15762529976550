/**
 * Tomas Costantino
 * 
 * Website hero section
 */

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

const HeroSection = () => {
    return (
        <section className="hero text-center" id="hero">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <h1>Transforming business operations through custom software solutions</h1>
                        <p>Your partner in automating business processes.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HeroSection;
