/**
 * Tomas Costantino
 * 
 * Analytics tag for Google Analytics
 */

import { useEffect } from 'react';

const GoogleAnalyticsTag = () => {
    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-B69L0KRXY7';
        document.body.appendChild(scriptTag);

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        window.gtag = gtag;

        gtag('js', new Date());
        gtag('config', 'G-B69L0KRXY7');

        return () => {
            document.body.removeChild(scriptTag);
        };
    }, []);

    return null; 
};

export default GoogleAnalyticsTag;
