/**
 * Tomas Costantino
 * 
 * Website about me
 */
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import profileImage from '../../Assets/profile_picture.png';
import './About.css';  

const About = () => {
    return (
        <section id="about" className="about-section">
            <Container>
                <Row className="align-items-center">  
                    <Col md={4} className="mb-3 mb-md-0">  
                        <Image src={profileImage} alt="Software Engineer" roundedCircle fluid />
                    </Col>
                    <Col md={8}>
                        <div className="about-text">
                            <h2>About Me</h2>
                            <p>Hello! I'm a software engineer that loves transforming ideas into digital solutions. I started with PIC microcontrollers and Arduino in high school. Over the years I've grown to design and develop robust APIs, web development, and cutting-edge AI technologies.</p>
                            <p>By having a strategic mindset, I aim to empower businesses and individuals by creating dynamic, user-centric software that allows them to better perform their tasks with the use of technology.</p>
                            <p>Let's innovate and solve real-world challenges together!</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
