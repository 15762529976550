/**
 * Tomas Costantino
 * 
 * Website project in portfolio
 */
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaReact, FaNodeJs, FaDatabase, FaMicrochip, FaBluetooth, FaAws, FaRobot, FaPython, FaNetworkWired } from 'react-icons/fa';
import './Project.css';

const iconComponents = {
    FaReact, FaNodeJs, FaDatabase, FaMicrochip, FaBluetooth, FaAws, FaRobot, FaPython, FaNetworkWired
};

const Project = ({ title, description, imageUrl, technologies, siteUrl }) => {
    return (
        <Card className="mb-4 shadow project-card">
            {imageUrl && <Card.Img variant="top" src={imageUrl} alt={title} />}
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
                <div className="technologies-used mb-3">
                    {technologies.map((tech, index) => {
                        const IconComponent = iconComponents[tech];
                        return IconComponent ? <IconComponent key={index} className="icon" /> : null;
                    })}
                </div>
                {siteUrl && (
                    <Button variant="primary" href={siteUrl} target="_blank" rel="noopener noreferrer">
                        Visit Website
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};
export default Project;
