/**
 * Tomas Costantino
 * 
 * Website: costantinosoftware.com
 */

import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import HeroSection from './components/HeroSection/HeroSection';
import About from './components/About/About';
import Services from './components/Services/Services';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';

import GoogleAnalyticsTag from './GoogleAnalyticsTag';

function App() {
  return (
    <div className="App">
      <GoogleAnalyticsTag />
      <Header />
      <main>
        <HeroSection />
        <About />
        <Services />
        <Portfolio />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
