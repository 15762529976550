/**
 * Tomas Costantino
 * 
 * Website footer
 */

import React from 'react';
import { Container } from 'react-bootstrap';
import { FaGithub } from 'react-icons/fa';
import './Footer.css'; 

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <div className="footer-content d-flex justify-content-between align-items-center">
                    <p>&copy; {new Date().getFullYear()} Costantino Software. All rights reserved.</p>
                    <div className="social-links">
                        <a href="https://github.com/tomycostantino" target="_blank" rel="noopener noreferrer">
                            <FaGithub />
                        </a>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;

