/**
 * Tomas Costantino
 * 
 * Website navbar
 */
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../../Assets/logo_transparent.png';

const Header = () => {
    const scrollToSection = (e) => {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');
        const offsetTop = document.querySelector(href).offsetTop - 75;

        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        src={logo}
                        height="30"
                        className="d-inline-block align-top"
                        alt="CostantinoSoftware Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#about" onClick={scrollToSection}>About</Nav.Link>
                        <Nav.Link href="#services" onClick={scrollToSection}>Services</Nav.Link>
                        <Nav.Link href="#portfolio" onClick={scrollToSection}>Portfolio</Nav.Link>
                        <Nav.Link href="#contact" onClick={scrollToSection}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
