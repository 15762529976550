/**
 * Tomas Costantino
 * 
 * Website contact me form
 */

import React, { useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import './Contact.css';

emailjs.init("6si_eOaGGl_SRii9j");

const Contact = () => {
    const form = useRef();
    const [loading, setLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm('service_oe7siln', 'template_izfgn0t', form.current, '6si_eOaGGl_SRii9j')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                alert("Email successfully sent!");
                setLoading(false);
            }, (error) => {
                console.log(error.text);
                alert("Failed to send the email, please try again.");
                setLoading(false);
            });
    };


    return (
        <section id="contact" className="contact-section">
            <h2>Contact Me</h2>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <input type="text" name="from_name" id="from_name" placeholder="Your Name" required />
                <input type="text" name="reply_to" id="reply_to" placeholder="Your Email" required />
                <textarea name="message" id="message" placeholder="Your Message" required></textarea>
                <Button className='submit-button'variant="primary" type="submit" disabled={loading}>
                    {loading ? (
                        <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span className="visually-hidden">Loading...</span>
                            Sending...
                        </>
                    ) : (
                        'Send Message'
                    )}
                </Button>
            </form>
        </section>
    );
};

export default Contact;
