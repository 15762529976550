/**
 * Tomas Costantino
 * 
 * Website portfolio
 */

import React from 'react';
import Project from './Project';
import { Container, Row, Col } from 'react-bootstrap';

import './Portfolio.css';

const projectsData = [
    {
        title: 'Now building Trabajito.app',
        description: "Task outsourcing platform for Latin America.",
        technologies: ['FaPython', 'FaDatabase', 'FaNetworkWired', 'FaAws', 'FaReact'],
        siteUrl: 'https://trabajito.app',
    },
    {
        title: 'Abada Capoeira Competition Management System',
        description: "RESTful API running serverless on AWS lambda along with frontend and database integration.",
        technologies: ['FaPython', 'FaDatabase', 'FaNetworkWired', 'FaAws', 'FaReact'],
    },
    {
        title: 'Man With A Van internal messaging system',
        description: "Django RESTful API and Websocket API integrate with GSM modem for SMS messaging.",
        technologies: ['FaPython', 'FaDatabase', 'FaNetworkWired'],
    },
    {
        title: 'Man with a Van Truck Fullness Estimator',
        description: 'An Arduino-based camera system with ML models to estimate truck load volume.',
        technologies: ['FaAws', 'FaMicrochip', 'FaBluetooth', 'FaRobot'],
    },
];

const Portfolio = () => {
    return (
        <Container fluid id="portfolio" className="portfolio-section py-5">
            <h2 className="text-center mb-4">Some of my projects</h2>
            <Row>
                {projectsData.map((project, index) => (
                    <Col sm={12} md={6} lg={4} key={index} className="mb-4"> 
                        <Project
                            title={project.title}
                            description={project.description}
                            imageUrl={project.imageUrl}
                            technologies={project.technologies}
                            siteUrl={project.siteUrl}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Portfolio;
