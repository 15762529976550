/**
 * Tomas Costantino
 * 
 * Website services
 */
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaCloud, FaDatabase, FaRobot, FaCode, FaNetworkWired, FaDocker, FaMicrochip, FaShieldAlt } from 'react-icons/fa';
import './Services.css';

const Services = () => {
    const services = [
        { icon: <FaCloud />, title: "Cloud Computing", description: "Leverage AWS ecosystem services for scalable and robust cloud solutions." },
        { icon: <FaNetworkWired />, title: "APIs & Websockets", description: "Design and integrate RESTful APIs and real-time communication with Websockets for dynamic applications." },
        { icon: <FaDatabase />, title: "Database Development and Management", description: "Efficient database solutions with optimized data storage to enhance data-driven applications." },
        { icon: <FaRobot />, title: "Machine Learning", description: "Implement AI and machine learning algorithms to automate complex tasks and uncover insights from your data." },
        { icon: <FaCode />, title: "Web Development", description: "Build dynamic, responsive websites that provide users with the best experience across all devices." },
        { icon: <FaDocker />, title: "Application Distribution", description: "Conteinarized applications to run seamlessly on any device." },
        { icon: <FaMicrochip />, title: "Internet of Things", description: "Integrate electronic devices into your ecosystem." },
        { icon: <FaShieldAlt />, title: "Secure Systems Design", description: "Specializing in the creation of secure systems to safeguard against digital threats." }
    ];

    return (
        <section id="services" className="services-section bg-light p-5">
            <h2 className="text-center mb-4">My Services</h2>
            <Row xs={1} md={2} lg={4} className="g-4">
                {services.map(service => (
                    <Col key={service.title}>
                        <Card className="service h-100 shadow-sm">
                            <Card.Body className="d-flex flex-column align-items-center">
                                <div className="service-icon mb-3">{service.icon}</div>
                                <Card.Title>{service.title}</Card.Title>
                                <Card.Text>{service.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </section>
    );
};

export default Services;
